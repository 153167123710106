import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { colors, cssCircle } from '@basisboard/basis-ui/es/styles'
import styled from 'styled-components'

const Circle = styled.div`
  ${cssCircle(128)};
  background-color: ${colors.error};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Failed = () => (
  <Circle>
    <Icon.Close color={colors.white} size={58} />
  </Circle>
)
