import { GetProfileResponse } from '@basisboard/basis-common/lib/api'
import * as Sentry from '@sentry/browser'
import { config, isDev } from '../config'

Sentry.init({
  dsn: config.SENTRY_DSN,
  release: process.env.COMMIT_SHA,
  ignoreErrors: [
    /ChunkLoadError/gi,
    /Request failed with status code/gi,
    /Request aborted/gi,
    /Invalid regular expression/gi,
  ],
})

export const bootSentry = ({ name, email, customerWorkspace, trial }: GetProfileResponse) =>
  Sentry.configureScope(function(scope) {
    scope.setTag('workspace', 'onboarding')
    scope.setUser({ email, username: name, customerWorkspace, trial })
  })

export const sentryLog = (e: any) => {
  if (isDev) return
  Sentry.captureException(e)
}
