import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { GlobalStyles } from '@basisboard/basis-ui/es/components/GlobalStyles'
import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { LogoText } from '@basisboard/basis-ui/es/components/Logo'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { H2, Text } from '@basisboard/basis-ui/es/components/Typography'
import { useTimer } from '@basisboard/basis-ui/es/hooks/useTimer'
import {
  borderRadiusMixin,
  boxShadowMixin,
  BreakpointType,
  colors,
  mediaBreakpointDown,
  mediaBreakpointUp,
  spacing,
} from '@basisboard/basis-ui/es/styles'
import { fromBoolean } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Image } from './components/Image'
import { Onboarding } from './containers/Onboarding'
import { OnboardingContainer } from './containers/Onboarding/container'
import { Support } from './containers/Support'
import { bootIntercom, EventType, logError, track } from './services'

const breakpoints = [
  `${BreakpointType.small}px`,
  `${BreakpointType.medium}px`,
  `${BreakpointType.large}px`,
  `${BreakpointType.xlarge}px`,
]

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
breakpoints.sm = breakpoints[0]
// @ts-ignore
breakpoints.md = breakpoints[1]
// @ts-ignore
breakpoints.lg = breakpoints[2]
// @ts-ignore
breakpoints.xl = breakpoints[3]
/* eslint-enable @typescript-eslint/ban-ts-comment */

const Container = styled.div<{ support?: boolean; children?: React.ReactNode }>`
  background: white;
  padding: ${spacing(4)} ${spacing(5)};
  max-width: 100%;
  min-height: ${({ support }) => (support ? 412 : 640)}px;
  display: flex;

  ${mediaBreakpointDown('medium')} {
    width: 100%;
  }

  ${mediaBreakpointUp('medium')} {
    ${borderRadiusMixin(20)};
    ${boxShadowMixin};
  }

  h3 {
    font-size: 1.25rem;
    font-weight: bold;

    color: #1c2c40;

    text-align: center;

    span {
      font-size: 0.875rem;
      font-weight: normal;
    }
  }
`

function App() {
  const { startTimer, getTimer, getDiffInMinutes } = useTimer()
  const onboardingData = useContainer(OnboardingContainer)

  const token = React.useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('token') ?? undefined
  }, [])

  const userId = React.useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('userId') ?? undefined
  }, [])

  const support = React.useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('support') === 'true'
  }, [])

  const scraperId = React.useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('sid')
  }, [])

  React.useEffect(() => {
    startTimer()

    if (window.location.href.includes('#')) {
      window.location.href = window.location.href.replace('#', '')
    } else {
      if (!token && !userId) {
        logError(new Error('Missing token'))
        return
      }

      bootIntercom({})

      onboardingData.auth({ token, userId })
    }
  }, [])

  React.useEffect(() => {
    if (onboardingData.state.currentStep === 3) {
      track({
        type: EventType.ConcludedFlow,
        meta: { duration: getTimer(), readableDuration: getDiffInMinutes(Date.now()) },
      })
    }
  }, [onboardingData.state.currentStep])

  React.useEffect(() => {
    const onBeforeUnload = () =>
      onboardingData.state.currentStep !== 3 &&
      track({
        type: EventType.AbandonedFlow,
        meta: {
          step:
            ['Create User', 'Sync Email', 'Sync Platforms'][onboardingData.state.currentStep] || '',
        },
      })
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  })

  const { startedFlow, customer } = onboardingData.state

  if (!customer) {
    return (
      <Row position="fixed" left={0} top={0} right={0} bottom={0}>
        <LoadingIndicator />
      </Row>
    )
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <ThemeProvider
      theme={{
        breakpoints,
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <GlobalStyles />
      <Div
        display={['block', 'block', 'flex']}
        alignItems="center"
        justifyContent="center"
        position={['relative', 'relative', 'fixed']}
        left={0}
        right={0}
        top={0}
        bottom={0}
        background={[colors.white, colors.white, colors.lightGray3]}
      >
        <Container support={support}>
          {support ? (
            <Support scraperId={scraperId} />
          ) : (
            fromBoolean(startedFlow).fold(
              () => (
                <Row flexDirection="column" width={[1, 1, 762]}>
                  <LogoText size={120} />
                  <Div mt={spacing(6)} mb={spacing(4)}>
                    <Image.Celebrating />
                  </Div>
                  <H2 textAlign="center" as="h1" mb={spacing(2)}>
                    Welcome to Basis, {customer.customer.name}!
                  </H2>
                  <Text
                    width={[1, 1, 640]}
                    preventClip
                    textAlign="center"
                    color={colors.gray}
                    as="h1"
                    mb={spacing(4)}
                  >
                    We’re glad you’re here. Basis helps you optimize your bid board, automatically
                    scrape the data from ITBs and get complex analytics simply, on your bidding
                    process.
                  </Text>
                  <Button.Primary height={48} onClick={onboardingData.onStartFlow}>
                    Let’s Get Started
                  </Button.Primary>
                </Row>
              ),
              () => <Onboarding />,
            )
          )}
        </Container>
      </Div>
    </ThemeProvider>
  )
}

export default App
