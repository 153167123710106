"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InboxOptions = void 0;
const api_1 = require("@basisboard/basis-common/lib/api");
const polished_1 = require("polished");
const React = __importStar(require("react"));
const Checkbox_1 = require("../Checkbox");
const Div_1 = require("../Div");
const Radio_1 = require("../Radio");
const InboxOptions = ({ onChange, initialValue }) => {
    const [dontMove, setDontMove] = React.useState([
        api_1.ScraperSettingsType.PlatformEmailsInInbox,
        api_1.ScraperSettingsType.PlatformEmailsInInboxIncludeRawITBs,
    ].includes(initialValue));
    const [allowRaw, setAllowRaw] = React.useState([
        api_1.ScraperSettingsType.MovePlatformEmailsIncludeRawITBs,
        api_1.ScraperSettingsType.PlatformEmailsInInboxIncludeRawITBs,
    ].includes(initialValue));
    const [value, setValue] = React.useState([
        api_1.ScraperSettingsType.PlatformEmailsInInbox,
        api_1.ScraperSettingsType.PlatformEmailsInInboxIncludeRawITBs,
        api_1.ScraperSettingsType.MovePlatformEmailsIncludeRawITBs,
    ].includes(initialValue)
        ? api_1.ScraperSettingsType.MovePlatformEmails
        : initialValue !== null && initialValue !== void 0 ? initialValue : api_1.ScraperSettingsType.MovePlatformEmails);
    const getValue = React.useCallback(() => {
        if (value === api_1.ScraperSettingsType.MovePlatformEmails) {
            if (!allowRaw && !dontMove) {
                return value;
            }
            if (dontMove && !allowRaw) {
                return api_1.ScraperSettingsType.PlatformEmailsInInbox;
            }
            if (allowRaw && !dontMove) {
                return api_1.ScraperSettingsType.MovePlatformEmailsIncludeRawITBs;
            }
            return api_1.ScraperSettingsType.PlatformEmailsInInboxIncludeRawITBs;
        }
        else {
            return value;
        }
    }, [value, allowRaw, dontMove]);
    React.useEffect(() => {
        onChange(getValue());
    }, [value, allowRaw, dontMove]);
    const handleChange = (val) => {
        setDontMove(false);
        setAllowRaw(false);
        setValue(val);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { className: "visually-hidden", name: "syncOption", value: getValue() }),
        React.createElement(Radio_1.Radio, { options: [
                {
                    value: api_1.ScraperSettingsType.OnlyBasisBoardFolder,
                    header: 'I’ll Pick',
                    description: 'You move all emails you want to go to Basis to a specific folder in your email mailbox.',
                },
                {
                    value: api_1.ScraperSettingsType.MovePlatformEmails,
                    header: 'All Bid Related Emails',
                    description: "We'll import all of the emails that we identify as being from a bid platform (e.g. Building connected.) You can manually add anything you also want to keep track of too.",
                    recommended: true,
                    children: (React.createElement(Div_1.Div, null,
                        React.createElement(Checkbox_1.Checkbox, { value: dontMove, disabled: value !== api_1.ScraperSettingsType.MovePlatformEmails, onChange: e => setDontMove(e.currentTarget.checked), label: "Don\u2019t move from my Inbox", labelStyles: { fontWeight: 400, fontSize: (0, polished_1.rem)(12) } }),
                        React.createElement(Checkbox_1.Checkbox, { value: allowRaw, disabled: value !== api_1.ScraperSettingsType.MovePlatformEmails, onChange: e => setAllowRaw(e.currentTarget.checked), label: "Also sync non-platform emails", labelStyles: { fontWeight: 400, fontSize: (0, polished_1.rem)(12) } }))),
                },
                {
                    value: api_1.ScraperSettingsType.AllEmailsInInbox,
                    header: 'All Emails',
                    description: 'This is best for those email accounts that are a catch-all for your estimating emails (e.g. estimating@yourco.com)',
                },
            ], defaultValue: value, onChange: handleChange })));
};
exports.InboxOptions = InboxOptions;
