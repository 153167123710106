import { ScraperSettingsDelay } from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Radio } from '@basisboard/basis-ui/es/components/Radio'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { box } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { BaseStep } from '../../../../../../components/BaseStep'
import { OnboardingContainer } from '../../../../container'
import { ButtonWrapper } from '../../../../styled'

export enum TimeRange {
  NOW = 'Now',
  THREE_DAYS = '3 days ago',
  ONE_WEEK = '1 week ago',
}

const mapDaysToDescription: Record<keyof typeof TimeRange, string> = {
  NOW: 'We’ll sync from now forward. No historical emails.',
  THREE_DAYS: 'We’ll sync emails from up to 3 days ago.',
  ONE_WEEK: 'We’ll sync emails from up to 7 days ago.',
}

const mapTimeRangeToDays: Record<keyof typeof TimeRange, number> = {
  NOW: 0,
  THREE_DAYS: 3,
  ONE_WEEK: 7,
}

const mapDaysToTimeRange: Record<number, keyof typeof TimeRange> = {
  0: 'NOW',
  3: 'THREE_DAYS',
  7: 'ONE_WEEK',
}

export const mapScraperSettingsDelayToDays: Record<ScraperSettingsDelay, number | undefined> = {
  [ScraperSettingsDelay.Now]: 0,
  [ScraperSettingsDelay.ThreeDays]: 3,
  [ScraperSettingsDelay.SevenDays]: 7,
  [ScraperSettingsDelay.Unknown]: undefined,
}

interface Props {
  onConclude: () => void
  onBack: () => void
}

export const SelectScrapedPeriod: React.FC<Props> = ({ onConclude, onBack }) => {
  const onboardingData = useContainer(OnboardingContainer)
  const [initTimeFrameInDays, setInitTimeFrameInDays] = React.useState(7)

  React.useEffect(() => {
    const initTimeFrameInDays =
      mapScraperSettingsDelayToDays[
        onboardingData.state.customer.scraperSettingsDelay || ScraperSettingsDelay.Unknown
      ]

    if (typeof initTimeFrameInDays === 'number') {
      onConclude()
    }
  }, [])

  if (onboardingData.state.customer.scraperSettingsDelay) {
    return null
  }

  const handleChangeValue = (val: string) =>
    setInitTimeFrameInDays(mapTimeRangeToDays[val as keyof typeof TimeRange])

  return (
    <BaseStep
      textAlign="left"
      title="How far back should we go?"
      description="Sync emails from your recent emails, to kickstart your Basis Bid Board."
      loading={onboardingData.state.loading}
    >
      <Radio
        space={{ mt: spacing(4) }}
        name="scape-period"
        defaultValue={mapDaysToTimeRange[initTimeFrameInDays] || Object.keys(TimeRange)[0]}
        onChange={handleChangeValue}
        options={Object.keys(TimeRange).map((keyStr: string) =>
          box(keyStr as keyof typeof TimeRange).fold(key => ({
            header: TimeRange[key],
            description: mapDaysToDescription[key],
            recommended: key === 'ONE_WEEK',
            value: key,
          })),
        )}
      />

      <ButtonWrapper>
        <Button.Text onClick={onBack} color={colors.gray}>
          Back
        </Button.Text>

        <Button.Primary
          type="submit"
          height={48}
          onClick={() =>
            onboardingData.updateScraper({ initTimeFrameInDays, active: true }, onConclude)
          }
        >
          Save Sync Settings
        </Button.Primary>
      </ButtonWrapper>
    </BaseStep>
  )
}
