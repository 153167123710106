import { Button } from '@basisboard/basis-ui/es/components/Button'
import { LinksList } from '@basisboard/basis-ui/es/components/LinksList'
import { spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { BaseStep } from '../../../../components/BaseStep'
import { EventType, track } from '../../../../services'
import { OnboardingContainer } from '../../container'

export const Concluded = () => {
  const onboardingData = useContainer(OnboardingContainer)

  React.useEffect(() => {
    track({ type: EventType.NavigatedToSuccessPage })
  }, [])

  return (
    <BaseStep
      image="Concluded"
      title="You’re all set!"
      description="We’re busy processing your emails and getting your account set up.<br/>Next up, you’ll have a call with one of our team.<br/><br/>You can close this window."
    >
      <LinksList
        links={[
          {
            ctaText: 'View Quick Overview',
            description: 'Take a tour of Basis, get to know it’s features and how it works.',
            url: 'https://www.basisboard.com/why-basisboard/how-it-works',
            target: '_blank',
          },
          {
            ctaText: 'View Customer Success Stories',
            description: 'See how our other customers use Basis, and what they think of it.',
            url: 'https://www.basisboard.com/customer-stories',
            target: '_blank',
          },
          {
            ctaText: 'View Support Articles',
            description: 'Learn, in-depth, how Basis works.',
            url:
              'https://intercom.help/basis-board/en/collections/2160467-basisboard-knowledge-base',
            target: '_blank',
          },
        ]}
      />

      {onboardingData.state.customer.preparedBoard && (
        <Button.Primary
          mt={spacing(3)}
          px={spacing(2.5)}
          mx="auto"
          height={48}
          href={`https://${onboardingData.state.customer.customer.workspace}.basisboard.com`}
        >
          Take me to my account
        </Button.Primary>
      )}
    </BaseStep>
  )
}
