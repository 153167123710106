import { borderRadiusMixin, colors, spacing, transitionMixin } from '@basisboard/basis-ui/es/styles'
import * as React from 'react'
import styled, { css } from 'styled-components'

const Box = styled.label<{ selected?: boolean }>`
  width: 108px;
  height: 126px;
  border: 1px solid ${colors.blueGrayBorder};
  margin: 0 ${spacing(1)};
  padding: ${spacing(2)} 0;
  position: relative;
  box-sizing: border-box;

  ${borderRadiusMixin};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${transitionMixin(undefined, 'border-color')};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    margin-bottom: ${spacing(2)};
  }

  ${({ selected }) =>
    selected &&
    css`
      margin: -1px ${spacing(1)};
      height: 128px;
      border-color: ${colors.blueGray600};
      border: 2px solid ${colors.blueGray600};
    `};
`

export interface OptionBoxProps {
  imageProps: Pick<Required<React.HTMLProps<HTMLImageElement>>, 'src' | 'alt' | 'style'>
  selected?: boolean
  text: string
  as?: 'li'
  children?: React.ReactNode
}

export const OptionBox = ({ children, selected, imageProps, text, as }: OptionBoxProps) => {
  return (
    <Box selected={selected} as={as}>
      <img {...imageProps} alt={imageProps.alt} />
      {text}

      {children}
    </Box>
  )
}
