import {
  PostSlacksMessagesRequestBody,
  PostSlacksMessagesResponseBody,
} from '@basisboard/basis-common/lib/api'
import { post } from '../../api'

export const postSlackMessage = (data: PostSlacksMessagesRequestBody) =>
  post<PostSlacksMessagesResponseBody>('/slacks/messages', data)
    .then(response => response.data.threadTs)
    .catch(() => null)
