import { Channel } from '@basisboard/basis-common/lib/api'
import { LocalStorageContainer } from '@containrz/container-local-storage'
import { isDev } from '../../config'
import { postSlackMessage } from './api'

interface State {
  threadId: string | null
}

export class SlackContainer extends LocalStorageContainer<State> {
  constructor() {
    super({
      threadId: null,
    })
  }

  sendSlackMessage = (text: string) =>
    !isDev &&
    postSlackMessage({
      channel: Channel.Onboarding,
      text,
      threadTs: this.state.threadId || undefined,
    }).then(threadId => this.setState({ threadId }))
}
