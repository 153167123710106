import { GetProfileResponse } from '@basisboard/basis-common/lib/api'

export const identifyHeap = async ({
  customerWorkspace,
  email,
  id,
  name,
  role,
  trial,
}: GetProfileResponse) => {
  window.heap.identify?.(id)

  window.heap.addUserProperties?.({
    Name: name,
    Email: email,
    Workspace: customerWorkspace,
    Role: role,
    IsOnTrial: trial,
    DisplayName: `${name} @ ${customerWorkspace}`,
  })
}

export const logHeap = (evt: any, params?: any) => {
  window.heap.track?.(evt, params)
}

export const shutDownHeap = () => window.heap.resetIdentity?.()
