import { USER_ID } from '@basisboard/basis-common/lib/api'
import { stringify } from 'qs'
import { config } from '../../../../config'

export const getNylasAuthUrl = (email: string, userId: USER_ID) => {
  const queryParamsConfig = {
    login_hint: email,
    response_type: 'code',
    client_id: config.NYLAS_CLIENT_ID as string,
    redirect_uri: `${config.API_URL}/email-scrapers/nylas-token/onboarding`,
    scopes: 'email.read_only,email.modify,email.folders_and_labels,email.send',
    redirect_on_error: 'true',
    state: stringify({
      email,
      userId,
    }),
  }

  return `https://api.us.nylas.com/v3/connect/auth?${stringify(queryParamsConfig)}`
}
