import axios, { AxiosError } from 'axios'
import { config } from './config'

axios.defaults.baseURL = config.API_URL || 'https://api.basisboard.com/v1'
axios.defaults.withCredentials = true
axios.defaults.headers = {
  'Content-Type': 'application/json',
}

const { get, patch, post, put, delete: del } = axios

export { get, patch, put, post, del }

export type ApiError = AxiosError
