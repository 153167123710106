import { Platform } from '@basisboard/basis-common/lib/enums'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Icon } from '@basisboard/basis-ui/es/components/Icon'
import { Input } from '@basisboard/basis-ui/es/components/Input'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { DetailText } from '@basisboard/basis-ui/es/components/Typography'
import { useForm } from '@basisboard/basis-ui/es/hooks/useForm'
import {
  colors,
  cssCircle,
  mediaBreakpointDown,
  mediaBreakpointUp,
  spacing,
} from '@basisboard/basis-ui/es/styles'
import { box, fromEmpty, fromNullable, mapPlatformToString } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import { rem } from 'polished'
import isEmpty from 'ramda/src/isEmpty'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { BaseStep } from '../../../../components/BaseStep'
import { EventType, track } from '../../../../services'
import { OptionBox } from '../../components/OptionBox'
import { OnboardingContainer } from '../../container'
import { ButtonWrapper } from '../../styled'
import { mapPlatformToImageProps, SYNCABLE_PLATFORMS } from './constants'

export const PlatformsList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${spacing(3)};
  padding: 0;
  list-style: none;
  width: 100%;
  flex-wrap: wrap;

  ${mediaBreakpointDown('medium')} {
    justify-content: center;
    li {
      width: 40%;
      margin-bottom: ${spacing(2)};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li:nth-child(odd) {
      margin-right: ${spacing(2)};
    }
  }
`

export const ListEntry = styled.li`
  label {
    cursor: pointer;
    ${mediaBreakpointDown('medium')} {
      width: 100%;
    }
  }
`

export const Form = styled.form`
  input {
    margin-bottom: ${spacing(2)};
  }

  width: 100%;

  ${mediaBreakpointUp('medium')} {
    width: 340px;
  }
`

export const SelectionCircle = styled.div<{ creating?: boolean; synced?: boolean }>`
  position: absolute;
  right: 6px;
  top: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${cssCircle(20)};
  border: 1px solid ${colors.blueGrayBorder};

  ${({ synced }) =>
    synced &&
    css`
      background-color: ${colors.success};
      border-color: ${colors.success};
    `}

  ${({ creating }) =>
    creating &&
    css`
      &::after {
        content: '';
        position: absolute;
        ${cssCircle(10)};
        background-color: ${colors.success};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `};
`

export const Platforms = () => {
  const [editingPlatform, setEditingPlatform] = React.useState<Platform>()
  const onboardingData = useContainer(OnboardingContainer)

  React.useEffect(() => {
    track({ type: EventType.NavigatedToPlatformSync })
  }, [])

  const { handleSubmit } = useForm<{ username: string; password: string }>(data => {
    if (!editingPlatform) {
      return
    }

    onboardingData.connectPlatform({ platformId: editingPlatform, ...data }, () =>
      setEditingPlatform(undefined),
    )
  })

  const { loading, error, syncedPlatforms } = onboardingData.state

  return (
    <>
      <BaseStep
        title="Connecting to Bidding Platforms"
        description="To guarantee accuracy and get up to date information, we’re able to connect to the bid
        platforms you use. Some of those platforms require credentials to access. You can provide
        those below. Select all of the platforms you use."
        textAlign="left"
        loading={loading}
      >
        <PlatformsList>
          {SYNCABLE_PLATFORMS.map(platform =>
            box(syncedPlatforms.some(p => p.platformId === platform)).fold(synced => (
              <ListEntry
                key={platform}
                role="option"
                aria-selected={editingPlatform === platform}
                tabIndex={editingPlatform ? -1 : 0}
                style={{ position: 'relative' }}
                onClick={
                  synced
                    ? undefined
                    : () => {
                        setEditingPlatform(platform)
                        onboardingData.clearError()
                      }
                }
                onKeyDown={
                  synced
                    ? undefined
                    : e => {
                        if (['Enter', ' '].includes(e.key)) {
                          setEditingPlatform(platform)
                          onboardingData.clearError()
                        }
                      }
                }
              >
                <OptionBox
                  imageProps={mapPlatformToImageProps[platform]}
                  text={mapPlatformToString[platform]}
                >
                  <SelectionCircle synced={synced} creating={editingPlatform === platform}>
                    {synced && <Icon.Check size={14} />}
                  </SelectionCircle>
                </OptionBox>
              </ListEntry>
            )),
          )}
        </PlatformsList>

        {!editingPlatform ? (
          <Button.Text
            color={colors.mediumGray}
            style={{ fontSize: rem(12), textDecoration: 'underline' }}
            mt={spacing(3)}
            height={24}
            onClick={() => {
              onboardingData.moveToNextStep()
              isEmpty(syncedPlatforms) && track({ type: EventType.SkippedPlatformSync })
            }}
          >
            {fromEmpty(syncedPlatforms).fold(
              () => `I’m done adding Platforms`,
              () => `Skip, I don’t have any of these`,
            )}
          </Button.Text>
        ) : (
          <Row style={{ alignSelf: 'flex-end', textAlign: 'left' }} width={1} mt={spacing(2)}>
            <Form onSubmit={handleSubmit}>
              <DetailText color={colors.darkBlue} fontWeight={600} as="p" mb={spacing(1.5)}>
                Connect to {mapPlatformToString[editingPlatform]}
              </DetailText>
              <Input name="username" required type="email" label="Email Address" />
              <Input name="password" required type="password" label="Password" />

              <ButtonWrapper>
                <Button.Text
                  color={colors.mediumGray}
                  style={{ fontSize: rem(12), textDecoration: 'underline' }}
                  height={24}
                  onClick={() => setEditingPlatform(undefined)}
                >
                  Cancel
                </Button.Text>
                <Button.Primary
                  ml={[0, 0, 'auto']}
                  type="submit"
                  height={48}
                  style={{ padding: `0 ${spacing(3)}`, fontSize: rem(16) }}
                  disabled={loading}
                >
                  {loading ? 'CONNECTING...' : 'CONNECT'}
                </Button.Primary>
              </ButtonWrapper>
              {error && (
                <DetailText textAlign="center" color={colors.error} as="p" mt={spacing(2)}>
                  {typeof error === 'string' ? (
                    error
                  ) : (
                    <>
                      Something went wrong while connecting to{' '}
                      {mapPlatformToString[editingPlatform]}.
                      <br /> Please, make sure all the provided information are correct.
                    </>
                  )}
                </DetailText>
              )}
            </Form>
          </Row>
        )}
      </BaseStep>

      {fromNullable(editingPlatform).fold(
        () => (
          <Row flexDirection="column" style={{ alignSelf: 'flex-end' }}>
            <DetailText>We automatically connect to the following Bid Platforms:</DetailText>
            <PlatformsList>
              {/* eslint-disable jsx-a11y/alt-text */}
              <li>
                <img {...mapPlatformToImageProps[Platform.Gradebeam]} />
              </li>
              <li>
                <img {...mapPlatformToImageProps[Platform.TheBluebook]} />
              </li>
              <li>
                <img {...mapPlatformToImageProps[Platform.Bidmail]} />
              </li>
              <li>
                <img {...mapPlatformToImageProps[Platform.Smartbid]} />
              </li>
              {/* eslint-enable jsx-a11y/alt-text */}
            </PlatformsList>
          </Row>
        ),
        () => null,
      )}
    </>
  )
}
