import { GetProfileResponse } from '@basisboard/basis-common/lib/api'
import smartlookClient from 'smartlook-client'
import { config } from '../config'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
smartlookClient.init(config.SMARTLOOK_KEY!, { region: 'eu' })

export const logSmartlook = (evt: any, params?: any) => smartlookClient.track(evt, params)

export const identifySmartlook = async ({
  customerWorkspace,
  email,
  id,
  name,
  role,
  trial,
  position,
  flags,
}: GetProfileResponse) =>
  smartlookClient.identify(id, {
    Name: name,
    Email: email,
    Workspace: customerWorkspace,
    Role: role,
    Position: position,
    IsOnTrial: trial,
    DisplayName: `${name} @ ${customerWorkspace}`,
    ...flags,
  })

export const shutDownSmartlook = () => smartlookClient.disable()

export const getSessionUrl = () => smartlookClient.playUrl
