import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

declare global {
  interface Window {
    FS: {
      identify: (key: string, params: unknown) => void
      shutdown: () => void
      event: (eventName: string, eventProperties?: unknown) => void
      getCurrentSessionURL: (now?: boolean) => string
    }
    heap: {
      load: (id: string) => Promise<void>
      identify: (id: string) => void
      addUserProperties: (config: unknown) => void
      track: (event: string, data: unknown) => void
      resetIdentity: () => void
    }
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
