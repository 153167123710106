import {
  EmailScraper,
  EmailScraperCreate,
  EmailScraperType,
  EMAIL_SCRAPER_ID,
  GetCustomerInvitationResponseBody,
  GetProfileResponse,
  PatchEmailScraperRequestBody,
  PlatformCredential,
  PostCustomerBody,
  PostEmailSupportImapBody,
  PostPlatformCredentialsRequestBody,
  PostSessionsResponse,
  ScraperSettingsType,
} from '@basisboard/basis-common/lib/api'
import { mapPlatformToString } from '@basisboard/basis-ui/es/utils'
import { Container, getContainer } from '@containrz/react-hook'
import omit from 'ramda/src/omit'
import { ApiError, get, post } from '../../api'
import { SlackContainer } from '../../data/slack/container'
import {
  bootIntercom,
  bootSentry,
  EventType,
  getSessionUrl,
  identifySmartlook,
  track,
} from '../../services'
import { identifyHeap } from '../../services/heap'
import {
  getCustomerByToken,
  getCustomerByUserId,
  getEmailScrapers,
  getPlatforms,
  patchEmailScrapers,
  postCustomer,
  postCustomerSupport,
  postEmailScrapers,
  postPlatform,
} from './api'

interface State {
  currentStep: number
  token: string
  startedFlow: boolean
  customer: GetCustomerInvitationResponseBody
  profile: GetProfileResponse
  scraperType: EmailScraperType
  syncedPlatforms: PlatformCredential[]
  loading: boolean
  error: boolean | string
  scraper: EmailScraper
  stepCompleted: boolean
  threadId: string | null
}

const TOTAL_STEPS = 4

export class OnboardingContainer extends Container<State> {
  private sendSlackMessage: (text: string) => void

  constructor() {
    super()

    const createdScraperId = new URLSearchParams(window.location.search).get('emailScraperId')

    this.state = {
      currentStep: 0,
      stepCompleted: false,
      startedFlow: Boolean(createdScraperId),
      loading: false,
      error: false,
      syncedPlatforms: [] as PlatformCredential[],
      threadId: null,
    } as State

    this.sendSlackMessage = getContainer(SlackContainer).sendSlackMessage

    this.sendSlackMessage(`Session URL: ${getSessionUrl()}`)
  }

  login = () =>
    get('/sessions/csrf').then(() =>
      get<GetProfileResponse>('/profile')
        .then(response => response.data)
        .then(profile => {
          this.setState({ profile })
          bootIntercom(profile)
          bootSentry(profile)
          identifySmartlook(profile)
          identifyHeap(profile)
        })
        .then(() => getPlatforms().then(syncedPlatforms => this.setState({ syncedPlatforms }))),
    )

  auth = ({ userId, token }: { userId?: string; token?: string }) => {
    this.login()
      .then(() =>
        getEmailScrapers().then(({ emailScrapers }) => {
          const hasPendingScraper =
            emailScrapers[0]?.scraperSettingsType === ScraperSettingsType.Pending

          this.setState({
            currentStep: emailScrapers.length > 0 && !hasPendingScraper ? 2 : 1,
            scraper: emailScrapers[0],
          })
        }),
      )
      .finally(() => {
        const successCallback = (customer: GetCustomerInvitationResponseBody) => {
          this.setState({
            customer,
            token,
          })

          track({ type: EventType.StartedSession, meta: customer })

          return customer
        }
        if (userId) {
          getCustomerByUserId(userId).then(successCallback)
          return
        }
        if (token) {
          getCustomerByToken(token).then(successCallback)
        }
      })
  }

  onStartFlow = () => this.setState({ startedFlow: true })

  private changeStep = (delta: 1 | -1) =>
    this.setState(s => ({
      currentStep: Math.min(TOTAL_STEPS, Math.max(0, s.currentStep + delta)),
      startedFlow: !(s.currentStep === 0 && delta === -1),
      error: false,
      loading: false,
      stepCompleted: false,
    }))

  moveToNextStep = () => this.changeStep(1)

  moveToPreviousStep = () => this.changeStep(-1)

  setScraperType = (scraperType: EmailScraperType) => this.setState({ scraperType })

  clearError = () => this.setState({ error: false })

  connectPlatform = (data: PostPlatformCredentialsRequestBody, onSuccess: () => void) => {
    this.setState({ loading: true })

    return postPlatform(this.state.token, data)
      .then(platform => {
        onSuccess()
        this.setState(s => ({
          loading: false,
          error: false,
          syncedPlatforms: [...s.syncedPlatforms, platform],
        }))

        track({ type: EventType.SyncedPlatform, meta: { platformId: data.platformId } })

        this.sendSlackMessage(`Synced ${mapPlatformToString[data.platformId]} account`)

        return platform
      })
      .catch((e: ApiError) => {
        this.setState({ loading: false, error: e.response?.data.message })
        track({
          type: EventType.SyncPlatformFailed,
          meta: { platformId: data.platformId, error: e.response?.data.message },
        })
      })
  }

  createUser = (data: PostCustomerBody, onSuccess: () => void) => {
    this.setState({ loading: true })

    return postCustomer(this.state.token, data)
      .then(() =>
        post<PostSessionsResponse>('/sessions', {
          email: data.user.email.toLowerCase(),
          password: data.user.password,
        }).then(this.login),
      )
      .then(() => {
        this.setState({ loading: false })

        track({ type: EventType.CreatedUser, meta: omit(['password'], data.user) })

        this.sendSlackMessage(
          `A new user called "${data.user.firstName} ${data.user.lastName}" was added.`,
        )
      })
      .then(onSuccess)
      .catch((e: ApiError) => {
        this.setState({ error: e.response?.data.message || true, loading: false })
        this.sendSlackMessage(`Error while creating user: \`${e.response?.data.message}\``)

        if ((this.state.error as string).includes('outside of trial')) {
          track({
            type: EventType.AttemptedToCreateOutsideTrial,
            meta: { workspace: this.state.customer.customer.workspace },
          })
        }
      })
  }

  createScraper = (data: Partial<EmailScraperCreate>, onSuccess: () => void) => {
    this.setState({ loading: true })

    return postEmailScrapers(data)
      .then(response => {
        this.setState({ scraper: response.emailScraper, loading: false })

        onSuccess()

        track({ type: EventType.SyncedEmail, meta: { email: data.email, type: data.type } })
        this.sendSlackMessage(`An email was synced.`)

        return response.emailScraper
      })
      .catch((e: ApiError) => {
        this.setState({
          error: e.response?.data?.message || 'Something went wrong while syncing your email',
          loading: false,
        })
        track({
          type: EventType.SyncEmailFailed,
          meta: { type: data.type, error: e.response?.data?.message },
        })
        this.sendSlackMessage(`Error while syncing email: \`${e.response?.data.message}\``)
      })
  }

  updateScraper = (
    data: PatchEmailScraperRequestBody,
    onSuccess: () => void,
    scraperId?: EMAIL_SCRAPER_ID,
  ) => {
    const scraper = scraperId ? { id: scraperId } : this.state.scraper
    if (!scraper) {
      return
    }

    this.setState({ loading: true })

    return patchEmailScrapers(scraper.id, data, this.state.token)
      .then(response => {
        this.setState({ scraper: response.emailScraper, loading: false, error: false })

        onSuccess()
      })
      .catch((e: ApiError) =>
        this.setState({
          error: e.response?.data?.message || "Couldn't connect to email server",
          loading: false,
        }),
      )
  }

  requestSupport = (data: PostEmailSupportImapBody, onSuccess: () => void) => {
    this.sendSlackMessage(`User requested support assistance to sync their email.`)

    this.setState({ loading: true, error: false })

    return postCustomerSupport(this.state.token, data)
      .then(onSuccess)
      .catch((e: ApiError) => {
        this.setState({ error: e.response?.data?.message, loading: false })
        this.sendSlackMessage(`Error while syncing email: \`${e.response?.data.message}\``)
      })
  }
}
