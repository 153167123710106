import { UserPosition } from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Input } from '@basisboard/basis-ui/es/components/Input'
import { Select } from '@basisboard/basis-ui/es/components/Select'
import { DetailText } from '@basisboard/basis-ui/es/components/Typography'
import { useForm } from '@basisboard/basis-ui/es/hooks/useForm'
import { colors, mediaBreakpointUp, spacing } from '@basisboard/basis-ui/es/styles'
import { mapUserPositionToString } from '@basisboard/basis-ui/es/utils'
import { useContainer } from '@containrz/react-hook'
import keys from 'ramda/src/keys'
import * as React from 'react'
import styled from 'styled-components'
import { BaseStep } from '../../../../components/BaseStep'
import { EventType, track } from '../../../../services'
import { OnboardingContainer } from '../../container'
import { ButtonWrapper } from '../../styled'

const InputsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: ${spacing(4)};
  text-align: left;
`

const Form = styled.form`
  position: relative;
`

const DataInput = styled(Input.Text)`
  margin-bottom: ${spacing(2)};
  width: 100%;

  ${mediaBreakpointUp('medium')} {
    width: calc(50% - ${spacing(1)});
  }
`

const getReadableError = (error: string): string => {
  if (error.includes('user.password')) {
    return 'Make sure the created password includes 1 letter, 1 number and has at least 6 characters'
  }
  if (error.includes('user.email')) {
    return 'This email appears not to be valid'
  }
  return error
}

export const UserDetails = () => {
  const onboardingData = useContainer(OnboardingContainer)

  React.useEffect(() => {
    track({ type: EventType.NavigatedToUserDetails })
  }, [])

  const { handleSubmit } = useForm(data =>
    onboardingData.createUser(data, onboardingData.moveToNextStep),
  )

  const data = onboardingData.state.customer
  if (!data) {
    return null
  }

  const { userEmail, userFirstName, userLastName, customer, userPosition } = data
  const { name, id } = customer

  return (
    <BaseStep
      image="Profile"
      textAlign="center"
      title="Tell us about yourself"
      description={`We’ll add you, with your colleagues, to your ${name} Basis account.`}
      loading={onboardingData.state.loading}
    >
      <Form onSubmit={handleSubmit}>
        <InputsWrapper>
          <DataInput
            height="small"
            defaultValue={userFirstName}
            label="First Name"
            name="user.firstName"
            required
          />

          <DataInput
            height="small"
            defaultValue={userLastName}
            label="Last Name"
            name="user.lastName"
            required
          />

          <DataInput
            height="small"
            defaultValue={userEmail}
            label="Email Address"
            name="user.email"
            type="email"
            required
          />

          <Select
            options={keys(mapUserPositionToString).map((key: UserPosition) => ({
              id: key,
              name: mapUserPositionToString[key],
            }))}
            name="user.position"
            sizeVariant="small"
            label="Position"
            width={[1, 1, `calc(50% - ${spacing(1)})`]}
            spaceProps={{ mb: spacing(2) }}
            hideEmpty
            initialValue={userPosition}
          />

          <DataInput
            height="small"
            label="Create Password"
            name="user.password"
            type="password"
            required
          />

          <input className="visually-hidden" name="customerId" defaultValue={id} />
        </InputsWrapper>

        {onboardingData.state.error && (
          <DetailText textAlign="left" color={colors.error} as="p">
            {typeof onboardingData.state.error === 'string'
              ? getReadableError(onboardingData.state.error) //onboardingData.state.error
              : 'Something went wrong while creating your account. Make sure you filled all request information correctly.'}
          </DetailText>
        )}
        <ButtonWrapper>
          <Button.Text onClick={onboardingData.moveToPreviousStep} color={colors.gray}>
            Back
          </Button.Text>
          <Button.Primary type="submit" height={48}>
            Let’s Get Started
          </Button.Primary>
        </ButtonWrapper>
      </Form>
    </BaseStep>
  )
}
