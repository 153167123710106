import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Input } from '@basisboard/basis-ui/es/components/Input'
import { useForm } from '@basisboard/basis-ui/es/hooks/useForm'
import { mediaBreakpointUp, spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import styled from 'styled-components'
import { BaseStep } from '../../components/BaseStep'
import { OnboardingContainer } from '../Onboarding/container'

const Form = styled.form`
  width: 100%;
  padding-top: ${spacing(3)};

  text-align: left;

  ${mediaBreakpointUp('medium')} {
    margin: 0 auto;
    width: 340px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: ${spacing(2)};
  }

  ${mediaBreakpointUp('medium')} {
    flex-direction: row;

    :nth-child(1) {
      margin-bottom: ${spacing(2)};
    }

    label {
      width: 200px;
    }
    label:nth-child(2) {
      width: 140px;
      margin-left: ${spacing(1)};
    }
  }
`

interface Props {
  scraperId: string | null
}

export const Support: React.FC<Props> = ({ scraperId }) => {
  const onboardingData = useContainer(OnboardingContainer)
  const [success, setSuccess] = React.useState(false)

  const { handleSubmit } = useForm(data => {
    if (scraperId) {
      onboardingData.updateScraper(
        { loginSettings: data, active: true },
        () => setSuccess(true),
        scraperId,
      )
    }
  })

  if (!scraperId) {
    return null
  }

  const { error, loading } = onboardingData.state
  const { customer, suggestedHost, suggestedPort } = onboardingData.state.customer

  if (success && !error) {
    return (
      <BaseStep
        image="Check"
        title="Test Successful!"
        description="Awesome, we were able to connect to their mailboxes and have started processing emails already. Thank you once again.<br/>You can close this window."
      />
    )
  }

  return (
    <BaseStep
      image={error ? 'Failed' : undefined}
      title={error ? 'Test failed' : `Set up emails for ${customer.name}`}
      description={
        error
          ? 'Looks like that didn’t work. You can change the settings or provide different test credentials:'
          : `Thank you for helping ${customer.name} get their emails set up with Basis. Below, you’ll provide the server and port # information for ${customer.name}’s emails. Then we’ll test to ensure it’s all working.`
      }
      loading={loading}
    >
      <Form onSubmit={handleSubmit}>
        <Row>
          <Input
            height="small"
            name="imap.host"
            defaultValue={suggestedHost || ''}
            label="IMAP Server Address"
            placeholder={`imap.${customer.workspace}.com`}
            required
          />

          <Input
            height="small"
            name="imap.port"
            defaultValue={suggestedPort?.toString() || ''}
            label="IMAP Port #"
            placeholder="993"
            required
          />
        </Row>

        <Row>
          <Input
            height="small"
            name="smtp.host"
            label="SMTP Server Address"
            placeholder={`smpt.${customer.workspace}.com`}
          />

          <Input height="small" name="smtp.port" label="SMTP Port #" placeholder="587" />
        </Row>

        <Div display="flex" width={1} justifyContent="flex-end" mt={spacing(2)}>
          <Button.Primary width={[1, 1, 'auto']} px={spacing(3)} type="submit" height={48}>
            Test Connection
          </Button.Primary>
        </Div>
      </Form>
    </BaseStep>
  )
}
