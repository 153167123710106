"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimationContainer = exports.Circle = exports.IndicatorLabel = exports.Indicator = exports.IndicatorItem = exports.IndicatorIcon = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const styled_system_1 = require("styled-system");
const styles_1 = require("../../styles");
const props_1 = require("./props");
exports.IndicatorIcon = styled_components_1.default.i `
  display: flex;
  margin: ${(0, styles_1.spacing)(1)};
  align-items: center;
  justify-content: center;
  position: relative;
  ${styles_1.transitionMixin};
  z-index: 1;

  ${({ dashed }) => !dashed &&
    (0, styled_components_1.css) `
      border: 1px solid ${styles_1.colors.blueGrayBorder};
    `};

  ${({ done }) => done &&
    (0, styled_components_1.css) `
      background-color: ${styles_1.colors.green};
      border: none;
    `}

  ${({ active, centerCircle }) => active &&
    (0, styled_components_1.css) `
      background: ${styles_1.colors.white};

      &::after {
        content: '';
        ${(0, styles_1.cssCircle)(8)};
        background-color: ${styles_1.colors.green};
        ${centerCircle &&
        `
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      }
    `}

  ${(0, styles_1.cssCircle)(24)};
`;
exports.IndicatorItem = styled_components_1.default.li `
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  position: relative;
  width: 100%;

  &:not(:last-of-type) {
    &::after {
      content: '';
      width: 70%;
      height: 2px;
      border-top: 2px dotted ${styles_1.colors.blueGrayBorder};
      position: absolute;
      left: 65%;
      top: 19px;
    }
  }
`;
exports.Indicator = styled_components_1.default.ol `
  position: relative;
  display: flex;
  width: 100%;
  margin: 0;
  -webkit-padding-start: 0;

  ${({ direction }) => direction === props_1.Directions.Vertical &&
    (0, styled_components_1.css) `
      ${exports.IndicatorItem} {
        height: 100%;
        flex-direction: row;

        &:not(:last-child) {
          &::after {
            border-top: none;
            border-left: 2px dotted ${styles_1.colors.blueGrayBorder};
            left: 19px;
            top: 65%;
            height: calc(70%);
            width: 2px;
          }
        }
      }
      flex-direction: column;
      /* justify-content: space-around; */
      height: 100%;
    `}
`;
exports.IndicatorLabel = styled_components_1.default.div `
  ${styled_system_1.position}
`;
const AppearKeyframes = (0, styled_components_1.keyframes) `
  0% {
    width: 0;
  }
  15% {
    width: 100%;
  }
  85% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
`;
const ShowTextKeyframes = (0, styled_components_1.keyframes) `
  0% {
    opacity: 0;
  }
  15%  {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  100%  {
    opacity: 0;
  }
`;
const ANIMATION_DURATION = '4s';
exports.Circle = styled_components_1.default.div `
  display: flex;
  align-items: center;
  justify-content: center;

  ${(0, styles_1.cssCircle)(24)};
  background-color: ${styles_1.colors.white};
`;
exports.AnimationContainer = styled_components_1.default.div `
  animation: ${AppearKeyframes};
  animation-duration: ${ANIMATION_DURATION};
  animation-timing-function: ease;

  position: absolute;
  left: 0;
  top: 0;
  bottom: -2px;

  border-radius: 0 0 ${styles_1.DEFAULT_BORDER_RADIUS}px ${styles_1.DEFAULT_BORDER_RADIUS}px;

  background-color: ${styles_1.colors.success};

  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    animation: ${ShowTextKeyframes};
    animation-duration: ${ANIMATION_DURATION};
    animation-timing-function: ease;
  }
`;
