import {
  EmailScraperType,
  GetCustomerInvitationResponseBody,
} from '@basisboard/basis-common/lib/api'
import { Platform } from '@basisboard/basis-common/lib/enums'
import { logHeap } from './heap'
import { sentryLog } from './sentry'
import { logSmartlook } from './smartlook'

export const logError = (error: Error) => {
  sentryLog(error)
  logSmartlook('[error]', error)
}

export const track = (data: EventInterfaces) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    console.log(data)
  }

  logSmartlook(data.type, data.meta)
  logHeap(data.type, data.meta)
}

export enum EventType {
  StartedSession = 'Onboarding - Started Session',
  NavigatedToUserDetails = 'Onboarding - Navigated to user details',
  NavigatedToEmailSync = 'Onboarding - Navigated to email sync',
  NavigatedToPlatformSync = 'Onboarding - Navigated to platform sync',
  NavigatedToSupportRequest = 'Onboarding - Navigated to support request',
  NavigatedToSuccessPage = 'Onboarding - Navigated to success page',
  ConcludedFlow = 'Onboarding - Concluded flow',
  CreatedUser = 'Onboarding - Created user',
  SyncedEmail = 'Onboarding - Synced email',
  SyncEmailFailed = 'Onboarding - Failed to sync email',
  SkippedEmailSync = 'Onboarding - Skipped email sync',
  SkippedPlatformSync = 'Onboarding - Skipped platform sync',
  SyncedPlatform = 'Onboarding - Synced platform',
  SyncPlatformFailed = 'Onboarding - Failed to sync platform',
  AbandonedFlow = 'Onboarding - Abandoned flow',
  AttemptedToCreateOutsideTrial = 'Onboarding - Attempted to create user outside of trial',
}

interface StartedSessionEvent {
  type: EventType.StartedSession
  meta: GetCustomerInvitationResponseBody
}

interface NavigatedToUserDetailsEvent {
  type: EventType.NavigatedToUserDetails
  meta?: Record<string, string>
}

interface NavigatedToEmailSyncEvent {
  type: EventType.NavigatedToEmailSync
  meta?: Record<string, string>
}

interface NavigatedToPlatformSyncEvent {
  type: EventType.NavigatedToPlatformSync
  meta?: Record<string, string>
}

interface NavigatedToSuccessPageEvent {
  type: EventType.NavigatedToSuccessPage
  meta?: Record<string, string>
}

interface NavigatedToSupportRequestEvent {
  type: EventType.NavigatedToSupportRequest
  meta?: Record<string, string>
}

interface ConcludedFlowEvent {
  type: EventType.ConcludedFlow
  meta: {
    duration: number
    readableDuration: string
  }
}

interface SkippedEmailSyncEvent {
  type: EventType.SkippedEmailSync
  meta?: Record<string, string>
}

interface SkippedPlatformSyncEvent {
  type: EventType.SkippedPlatformSync
  meta?: Record<string, string>
}

interface SyncedPlatformEvent {
  type: EventType.SyncedPlatform
  meta: {
    platformId: Platform
  }
}

interface SyncPlatformFailedEvent {
  type: EventType.SyncPlatformFailed
  meta: {
    platformId: Platform
    error?: string
  }
}

interface SyncedEmailEvent {
  type: EventType.SyncedEmail
  meta: {
    type?: EmailScraperType
    email?: string
  }
}

interface SyncEmailFailedEvent {
  type: EventType.SyncEmailFailed
  meta: {
    type?: EmailScraperType
    error: string
  }
}

interface AbandonedFlowEvent {
  type: EventType.AbandonedFlow
  meta?: {
    step: string
  }
}

interface CreatedUserEvent {
  type: EventType.CreatedUser
  meta: {
    firstName: string
    lastName: string
    email: string
  }
}

interface AttemptedToCreateOutsideTrialEvent {
  type: EventType.AttemptedToCreateOutsideTrial
  meta?: {
    workspace: string
  }
}

type EventInterfaces =
  | StartedSessionEvent
  | NavigatedToUserDetailsEvent
  | NavigatedToEmailSyncEvent
  | NavigatedToPlatformSyncEvent
  | NavigatedToSuccessPageEvent
  | NavigatedToSupportRequestEvent
  | ConcludedFlowEvent
  | SkippedEmailSyncEvent
  | SkippedPlatformSyncEvent
  | SyncedPlatformEvent
  | SyncPlatformFailedEvent
  | SyncedEmailEvent
  | SyncEmailFailedEvent
  | AbandonedFlowEvent
  | CreatedUserEvent
  | AttemptedToCreateOutsideTrialEvent
