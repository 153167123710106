import { Celebrating } from './Celebrating'
import { Check } from './Check'
import { Concluded } from './Concluded'
import { Failed } from './Failed'
import { Mailbox } from './Mailbox'
import { Profile } from './Profile'

export const Image = {
  Celebrating,
  Check,
  Concluded,
  Failed,
  Mailbox,
  Profile,
}

export type ImageType = keyof typeof Image
