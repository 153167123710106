import {
  CreateCustomerResponseBody,
  EmailScraperCreate,
  EMAIL_SCRAPER_ID,
  GetCustomerInvitationResponseBody,
  GetEmailScrapersResponseBody,
  GetPlatformCredentialsResponseBody,
  GetProfileResponse,
  PatchEmailScraperRequestBody,
  PatchEmailScraperResponseBody,
  PostCustomerBody,
  PostEmailScraperResponseBody,
  PostEmailSupportImapBody,
  PostPlatformCredentialsRequestBody,
  PostPlatformCredentialsResponseBody,
  USER_ID,
} from '@basisboard/basis-common/lib/api'
import { get, patch, post } from '../../api'

export const getProfile = () => get<GetProfileResponse>('/profile').then(response => response.data)

export const postEmailScrapers = (data: Partial<EmailScraperCreate>) =>
  post<PostEmailScraperResponseBody>('/email-scrapers', data).then(response => response.data)

export const getEmailScrapers = () =>
  get<GetEmailScrapersResponseBody>('/email-scrapers').then(response => response.data)

export const patchEmailScrapers = (
  emailScraperId: EMAIL_SCRAPER_ID,
  data: PatchEmailScraperRequestBody,
  token?: string,
) =>
  patch<PatchEmailScraperResponseBody>(
    `/email-scrapers/${emailScraperId}${token ? `?token=${token}` : ''}`,
    data,
    token
      ? {
          headers: {
            Authorization: token,
          },
        }
      : undefined,
  ).then(response => response.data)

export const postCustomer = (token: string, data: PostCustomerBody) =>
  post<CreateCustomerResponseBody>(`/customers?token=${token}`, data, {
    headers: {
      Authorization: token,
    },
  })

export const postCustomerSupport = (token: string, data: PostEmailSupportImapBody) =>
  post(`/customers/email-support-imap?token=${token}`, data, {
    headers: {
      Authorization: token,
    },
  })

export const getCustomerByToken = (token: string) =>
  get<GetCustomerInvitationResponseBody>(`/customers/invitation?token=${token}`).then(
    response => response.data,
  )

export const getCustomerByUserId = (userId: USER_ID) =>
  get<GetCustomerInvitationResponseBody>(`/customers/${userId}`).then(response => response.data)

export const getPlatforms = () =>
  get<GetPlatformCredentialsResponseBody>('/platform-credentials').then(
    response => response.data.credentials,
  )

export const postPlatform = (token: string, body: PostPlatformCredentialsRequestBody) =>
  post<PostPlatformCredentialsResponseBody>('/platform-credentials', body, {
    headers: {
      Authorization: token,
    },
  }).then(response => response.data)
