"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radio = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../styles");
const Typography_1 = require("../Typography");
const styled_1 = require("./styled");
__exportStar(require("./props"), exports);
const Radio = function (_a) {
    var { options, onChange, name, defaultValue, space, renderOption, direction = 'column', alignItems, justifyContent } = _a, rest = __rest(_a, ["options", "onChange", "name", "defaultValue", "space", "renderOption", "direction", "alignItems", "justifyContent"]);
    const [val, setVal] = React.useState(defaultValue);
    const handleChange = (e) => {
        setVal(e.target.value);
        onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value);
    };
    const renderHiddenInput = (opt, i) => (React.createElement(styled_1.HiddenInput, Object.assign({ id: `${name}-${i}`, type: "radio", name: name }, rest, { value: opt.value, onChange: handleChange, checked: val === opt.value })));
    return (React.createElement(styled_1.Box, Object.assign({}, space, { flexDirection: direction, alignItems: alignItems, justifyContent: justifyContent }), options.map((opt, i) => (renderOption === null || renderOption === void 0 ? void 0 : renderOption(opt, renderHiddenInput(opt, i), val === opt.value)) || (React.createElement(styled_1.RadioLabel, { checked: val === opt.value, key: opt.value },
        renderHiddenInput(opt, i),
        React.createElement(Typography_1.HeavyText, { lineHeight: "24px", preventClip: true },
            opt.header,
            opt.recommended && (React.createElement(Typography_1.Span, { color: styles_1.colors.gray, ml: (0, styles_1.spacing)(1) },
                ' ',
                "recommended"))),
        opt.description && (React.createElement(Typography_1.DetailText, { fontWeight: 400, color: styles_1.colors.gray, preventClip: true }, opt.description)),
        opt.children)))));
};
exports.Radio = Radio;
