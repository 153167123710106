"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressIndicator = void 0;
const React = __importStar(require("react"));
const styles_1 = require("../../styles");
const utils_1 = require("../../utils");
const Div_1 = require("../Div");
const Icon_1 = require("../Icon");
const Row_1 = require("../Row");
const Typography_1 = require("../Typography");
const props_1 = require("./props");
const styled_1 = require("./styled");
__exportStar(require("./props"), exports);
const ProgressIndicator = ({ current, direction = props_1.Directions.Horizontal, indicators, showActive, }) => {
    const didMountRef = React.useRef(false);
    const [animateStep, setAnimateStep] = React.useState(-1);
    React.useEffect(() => {
        if (didMountRef.current) {
            setAnimateStep(direction === props_1.Directions.Horizontal ? current : -1);
        }
        else {
            didMountRef.current = true;
        }
    }, [current]);
    return (React.createElement(styled_1.Indicator, { direction: direction },
        indicators.map((indicator, idx) => (React.createElement(styled_1.IndicatorItem, { key: idx },
            (0, utils_1.box)(!showActive ? idx <= current : idx < current).fold(done => (React.createElement(styled_1.IndicatorIcon, { "aria-hidden": true, active: showActive && idx === current, done: done, dashed: direction === props_1.Directions.Horizontal, centerCircle: showActive && direction === props_1.Directions.Horizontal }, direction === props_1.Directions.Horizontal && !done ? (React.createElement(Icon_1.Icon.DashedCircle, { color: styles_1.colors.blueGrayBorder })) : ((!showActive || idx < current) && React.createElement(Icon_1.Icon.Check, { color: styles_1.colors.white, size: 16 }))))),
            React.createElement(Div_1.Div, { mt: -1, ml: (0, styles_1.spacing)(1) }, direction === props_1.Directions.Vertical ? (React.createElement(React.Fragment, null,
                React.createElement(Typography_1.Label, { mt: Boolean(indicator.label) && (0, styles_1.spacing)(2.5), color: styles_1.colors.darkBlue }, indicator.title),
                indicator.label && React.createElement(Typography_1.DetailText, null, indicator.label))) : (React.createElement(Typography_1.DetailText, null, indicator.title)))))),
        animateStep >= 0 && (React.createElement(styled_1.AnimationContainer, { onAnimationEnd: () => setAnimateStep(-1) },
            React.createElement(Row_1.Row, { justifyContent: "center", width: 1 },
                React.createElement(styled_1.Circle, null,
                    React.createElement(Icon_1.Icon.Check, { color: styles_1.colors.success })),
                React.createElement(Typography_1.Subtitle, { ml: (0, styles_1.spacing)(2), white: true }, indicators[animateStep].title))))));
};
exports.ProgressIndicator = ProgressIndicator;
