import * as React from 'react'

export const Concluded = () => (
  <svg width="314" viewBox="0 0 640.65724 577.17886">
    <path
      d="M438.404,307.82132c-11.01605-32.92966-45.0348-51.23148-67.41427-43.7448s-38.5377,42.57432-27.52165,75.504c10.19672,30.48049,41.09425,48.07684,62.34943,44.93672a2.4111,2.4111,0,0,0-.29727.86443l-.64994,5.42078a2.44167,2.44167,0,0,0,3.24841,2.589l10.94888-3.92591a2.44172,2.44172,0,0,0,.8633-4.06338l-3.94671-3.77257a2.41653,2.41653,0,0,0-.46175-.33188C434.48011,371.08126,448.62292,338.36807,438.404,307.82132Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#f2f2f2"
    />
    <path
      d="M398.52421,283.59439a40.17042,40.17042,0,0,1,18.17735,52.25234c-.9474,2.15673,2.53662,3.29094,3.4778,1.1483A43.92349,43.92349,0,0,0,400.556,280.54609C398.48974,279.45115,396.44264,282.49133,398.52421,283.59439Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
    <path
      d="M884.404,259.82132c-11.016-32.92966-45.0348-51.23148-67.41427-43.7448s-38.5377,42.57432-27.52165,75.504c10.19672,30.48049,41.09425,48.07684,62.34943,44.93672a2.4111,2.4111,0,0,0-.29727.86443l-.64994,5.42078a2.44167,2.44167,0,0,0,3.24841,2.589l10.94888-3.92591a2.44172,2.44172,0,0,0,.8633-4.06338l-3.94671-3.77257a2.41653,2.41653,0,0,0-.46175-.33188C880.48011,323.08126,894.62292,290.36807,884.404,259.82132Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#f2f2f2"
    />
    <path
      d="M844.52421,235.59439a40.17042,40.17042,0,0,1,18.17735,52.25234c-.9474,2.15673,2.53662,3.29094,3.4778,1.1483A43.92349,43.92349,0,0,0,846.556,232.54609C844.48974,231.45115,842.44264,234.49133,844.52421,235.59439Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
    <path
      d="M625.404,206.82132c-11.016-32.92966-45.0348-51.23148-67.41427-43.7448s-38.5377,42.57432-27.52165,75.504c10.19672,30.48049,41.09425,48.07684,62.34943,44.93672a2.4111,2.4111,0,0,0-.29727.86443l-.64994,5.42078a2.44167,2.44167,0,0,0,3.24841,2.589l10.94888-3.92591a2.44172,2.44172,0,0,0,.8633-4.06338l-3.94671-3.77257a2.41653,2.41653,0,0,0-.46175-.33188C621.48011,270.08126,635.62292,237.36807,625.404,206.82132Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#f2f2f2"
    />
    <path
      d="M585.52421,182.59439a40.17042,40.17042,0,0,1,18.17735,52.25234c-.9474,2.15673,2.53662,3.29094,3.4778,1.1483A43.92349,43.92349,0,0,0,587.556,179.54609C585.48974,178.45115,583.44264,181.49133,585.52421,182.59439Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
    <circle cx="290.48241" cy="201.23783" r="2.62444" fill="#ccc" />
    <circle cx="284.8888" cy="303.95675" r="5.18452" fill="#ccc" />
    <circle cx="198.09305" cy="286.18213" r="2.99584" fill="#ccc" />
    <circle cx="243.60567" cy="236.87991" r="3.24432" fill="#ccc" />
    <circle cx="403.25036" cy="427.44691" r="2.62444" fill="#ccc" />
    <circle cx="317.30063" cy="370.92163" r="5.18452" fill="#ccc" />
    <circle cx="376.33906" cy="304.8618" r="2.99584" fill="#ccc" />
    <circle cx="396.04016" cy="369.00206" r="3.24432" fill="#ccc" />
    <ellipse
      cx="836.6349"
      cy="684.24158"
      rx="6.76007"
      ry="21.53369"
      transform="translate(-523.77203 535.2876) rotate(-39.93837)"
      fill="#2f2e41"
    />
    <circle cx="511.15638" cy="515.35311" r="43.06732" fill="#2f2e41" />
    <rect x="516.30144" y="549.15264" width="13.08374" height="23.44171" fill="#2f2e41" />
    <rect x="490.13396" y="549.15264" width="13.08374" height="23.44171" fill="#2f2e41" />
    <ellipse cx="518.48204" cy="572.86693" rx="10.90314" ry="4.08868" fill="#2f2e41" />
    <ellipse cx="492.31456" cy="572.3218" rx="10.90314" ry="4.08868" fill="#2f2e41" />
    <path
      d="M777.30491,622.62674c3.84558-15.487,20.82056-24.60077,37.91474-20.35617s27.83429,20.2403,23.9887,35.7273-16.60394,15.537-33.69812,11.29235S773.45933,638.11377,777.30491,622.62674Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#e6e6e6"
    />
    <ellipse
      cx="742.31957"
      cy="656.78005"
      rx="6.76007"
      ry="21.53369"
      transform="translate(-448.87657 884.62646) rotate(-64.62574)"
      fill="#2f2e41"
    />
    <circle cx="504.71776" cy="506.26552" r="14.35864" fill="#fff" />
    <circle cx="498.81639" cy="501.11873" r="4.78621" fill="#3f3d56" />
    <path
      d="M793.39653,697.59149a9.57244,9.57244,0,0,1-18.83533,3.42884h0l-.00336-.0185c-.94177-5.20215,3.08039-7.043,8.28253-7.98474S792.45481,692.38941,793.39653,697.59149Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
    <ellipse
      cx="430.43769"
      cy="644.24148"
      rx="21.53369"
      ry="6.76007"
      transform="translate(-604.69403 654.88722) rotate(-69.08217)"
      fill="#2f2e41"
    />
    <circle cx="110.95912" cy="515.35305" r="43.06735" fill="#2f2e41" />
    <rect x="91.33351" y="549.15265" width="13.08374" height="23.44171" fill="#2f2e41" />
    <rect x="117.50099" y="549.15265" width="13.08374" height="23.44171" fill="#2f2e41" />
    <ellipse cx="102.23666" cy="572.86693" rx="10.90314" ry="4.08868" fill="#2f2e41" />
    <ellipse cx="128.40414" cy="572.32178" rx="10.90314" ry="4.08868" fill="#2f2e41" />
    <circle cx="112.04946" cy="504.44983" r="14.71921" fill="#fff" />
    <circle cx="112.04945" cy="504.44983" r="4.90643" fill="#3f3d56" />
    <path
      d="M348.85377,636.7121c-3.47748-15.57379,7.63867-31.31043,24.82861-35.1488s33.94422,5.67511,37.4217,21.24884-7.91492,21.31762-25.10486,25.156S352.33125,652.286,348.85377,636.7121Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#e6e6e6"
    />
    <ellipse
      cx="342.12235"
      cy="656.7801"
      rx="6.76007"
      ry="21.53369"
      transform="translate(-677.57762 523.03713) rotate(-64.62574)"
      fill="#2f2e41"
    />
    <path
      d="M369.476,692.30048c0,4.21515,10.85328,12.53857,22.89658,12.53857s23.33514-11.867,23.33514-16.08209-11.29193.81775-23.33514.81775S369.476,688.08533,369.476,692.30048Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
    <circle cx="316.67837" cy="474.35295" r="43.06733" fill="#2f2e41" />
    <rect x="297.05274" y="508.15264" width="13.08374" height="23.44171" fill="#2f2e41" />
    <rect x="323.22024" y="508.15264" width="13.08373" height="23.44171" fill="#2f2e41" />
    <ellipse cx="307.95583" cy="531.86693" rx="10.90314" ry="4.08868" fill="#2f2e41" />
    <ellipse cx="334.12337" cy="531.32176" rx="10.90314" ry="4.08868" fill="#2f2e41" />
    <path
      d="M554.573,595.71222c-3.47747-15.57379,7.63866-31.31042,24.82866-35.1488s33.94422,5.67511,37.4217,21.2489-7.91492,21.31769-25.10486,25.156S558.05049,611.286,554.573,595.71222Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#6c63ff"
    />
    <ellipse
      cx="637.4534"
      cy="611.64234"
      rx="23.89244"
      ry="7.50055"
      transform="translate(-525.45495 468.82357) rotate(-45.0221)"
      fill="#2f2e41"
    />
    <ellipse
      cx="544.74805"
      cy="614.64234"
      rx="7.50055"
      ry="23.89244"
      transform="translate(-554.71702 403.49289) rotate(-44.9779)"
      fill="#2f2e41"
    />
    <path
      d="M858.1781,362.43165c-1.4297-44.15967-31.675-79.01642-67.55466-77.8548-79.853,2.36609-81.80308,148.80118-4.70743,159.32l-1.63226,12.97539,23.81077-.77089-2.46814-12.84263C836.64005,436.4039,859.47429,402.46761,858.1781,362.43165Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#ff6584"
    />
    <path
      d="M750.91418,386.91539l-8.99528.29122a146.49353,146.49353,0,0,1-1.65029-50.97329l8.99528-.29123Q744.28032,362.56747,750.91418,386.91539Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
    <rect
      x="782.36555"
      y="443.49202"
      width="27"
      height="6"
      transform="translate(-293.7025 -135.42359) rotate(-1.85434)"
      fill="#3f3d56"
    />
    <polygon
      points="451.208 511.273 448.491 510.001 515.303 283.429 518.02 284.702 451.208 511.273"
      fill="#3f3d56"
    />
    <path
      d="M913.47588,399.77386c16.3163-41.05969,2.49878-85.09205-30.86214-98.349-74.15988-29.70541-134.40019,103.77921-67.9117,144.19784l-6.676,11.2453,22.13927,8.7977,2.8634-12.76032C864.20072,459,898.68322,436.99934,913.47588,399.77386Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#6c63ff"
    />
    <path
      d="M805.35487,379.40605l-8.36382-3.32362a146.49359,146.49359,0,0,1,18.83383-47.395l8.36382,3.32362Q808.99136,354.434,805.35487,379.40605Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
    <rect
      x="819.78863"
      y="438.4737"
      width="6"
      height="27"
      transform="translate(-180.75644 888.28233) rotate(-68.32812)"
      fill="#3f3d56"
    />
    <polygon
      points="450.68 505.309 448.073 503.825 541.351 290.957 543.958 292.44 450.68 505.309"
      fill="#3f3d56"
    />
    <path
      d="M406.46357,365.28486c-17.653-40.503-58.64132-61.70977-91.54993-47.36676-73.32174,31.71782-21.00088,168.50073,54.522,149.77438l3.28,12.65965,21.8391-9.51844-7.04085-11.02049C413.79663,441.97911,422.46809,402.00562,406.46357,365.28486Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#6c63ff"
    />
    <path
      d="M315.84888,427.6866l-8.25042,3.5959a146.49372,146.49372,0,0,1-20.37675-46.75242l8.25043-3.59589Q300.68422,407.51577,315.84888,427.6866Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
    <rect
      x="366.13977"
      y="463.42577"
      width="27"
      height="6"
      transform="translate(-434.41032 29.11894) rotate(-23.54967)"
      fill="#3f3d56"
    />
    <path
      d="M448.08285,673.26319h0a1.49991,1.49991,0,0,1-1.81676-1.09466L381.05867,465.5523l2.91162-.722,65.20734,206.61591A1.49991,1.49991,0,0,1,448.08285,673.26319Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#3f3d56"
    />
    <path
      d="M465.51017,738.58943h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#ccc"
    />
    <path
      d="M864.51017,738.58943h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#ccc"
    />
    <path
      d="M677.51017,697.58943h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#ccc"
    />
    <circle cx="314.86635" cy="468.24973" r="14.71921" fill="#fff" />
    <circle cx="314.86635" cy="468.24974" r="4.90643" fill="#3f3d56" />
    <path
      d="M606.22226,656.88283a9.57244,9.57244,0,0,1-18.83533,3.42884h0l-.00335-.0185c-.94178-5.20215,3.08038-7.043,8.28253-7.98474S605.28055,651.68075,606.22226,656.88283Z"
      transform="translate(-279.67138 -161.41057)"
      fill="#fff"
    />
  </svg>
)
