import { LoadingIndicator } from '@basisboard/basis-ui/es/components/LoadingIndicator'
import { Row } from '@basisboard/basis-ui/es/components/Row'
import { Subtitle, Text } from '@basisboard/basis-ui/es/components/Typography'
import { colors, mediaBreakpointDown, spacing } from '@basisboard/basis-ui/es/styles'
import { rem, rgba } from 'polished'
import * as React from 'react'
import styled from 'styled-components'
import { Image, ImageType } from '../Image'

const ImageWrapper = styled(Row)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};
`

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100% - 48px);
  width: 480px;
  font-size: ${rem(14)};
  font-weight: 600;
  color: ${colors.gray600};
  text-align: center;

  position: relative;

  ${mediaBreakpointDown('medium')} {
    width: 100%;
  }
`

const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${rgba(colors.white, 0.5)};
`

interface Props {
  title: string
  description: string
  image?: ImageType
  textAlign?: 'center' | 'left' | 'right'
  loading?: boolean
  children?: React.ReactNode
}

export const BaseStep = ({ title, description, children, textAlign, image, loading }: Props) => {
  const Img = image ? Image[image] : null

  return (
    <StepWrapper>
      {Img && (
        <ImageWrapper>
          <Img />
        </ImageWrapper>
      )}

      <Subtitle textAlign={textAlign} as="h1" mb={spacing(2)}>
        {title}
      </Subtitle>

      <Text
        preventClip
        textAlign={textAlign}
        width={1}
        color={colors.gray}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      {children}

      {loading && (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
    </StepWrapper>
  )
}
