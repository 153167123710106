declare global {
  interface Window {
    REACT_APP_API_URL?: string
    REACT_APP_MICROSOFT_CLIENT_ID?: string
    REACT_APP_GOOGLE_CLIENT_ID?: string
    REACT_APP_SENTRY_DSN?: string
    REACT_APP_INTERCOM_APP_ID?: string
    REACT_APP_NYLAS_CLIENT_ID?: string
    REACT_APP_SMARTLOOK_KEY?: string
  }
}

export const config = {
  API_URL: window.REACT_APP_API_URL || process.env.REACT_APP_API_URL,
  MICROSOFT_CLIENT_ID:
    window.REACT_APP_MICROSOFT_CLIENT_ID || process.env.REACT_APP_MICROSOFT_CLIENT_ID,
  GOOGLE_CLIENT_ID: window.REACT_APP_GOOGLE_CLIENT_ID || process.env.REACT_APP_GOOGLE_CLIENT_ID,
  SENTRY_DSN: window.REACT_APP_SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN,
  INTERCOM_APP_ID: window.REACT_APP_INTERCOM_APP_ID || process.env.REACT_APP_INTERCOM_APP_ID,
  NYLAS_CLIENT_ID: window.REACT_APP_NYLAS_CLIENT_ID || process.env.REACT_APP_NYLAS_CLIENT_ID,
  SMARTLOOK_KEY: window.REACT_APP_SMARTLOOK_KEY || process.env.REACT_APP_SMARTLOOK_KEY,
}

export const isDev = process.env.NODE_ENV === 'development'
