import { ScraperSettingsType } from '@basisboard/basis-common/lib/api'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { InboxOptions } from '@basisboard/basis-ui/es/components/InboxOptions'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import * as React from 'react'
import { BaseStep } from '../../../../../../components/BaseStep'
import { OnboardingContainer } from '../../../../container'
import { ButtonWrapper } from '../../../../styled'

interface Props {
  onConclude: (value?: ScraperSettingsType) => void
  onBack: () => void
}

export const OptionsConfig: React.FC<Props> = ({ onConclude, onBack }) => {
  const onboardingData = useContainer(OnboardingContainer)
  const [value, setValue] = React.useState<ScraperSettingsType>(
    ScraperSettingsType.MovePlatformEmails,
  )

  React.useEffect(() => {
    if (onboardingData.state.customer.scraperSettingsType) {
      onConclude()
    }
  }, [])

  if (onboardingData.state.customer.scraperSettingsType) {
    return null
  }

  const handleChange = (val: ScraperSettingsType) => {
    setValue(val)
  }

  return (
    <BaseStep
      textAlign="left"
      title="Syncing option"
      description="Which emails should we sync?"
      loading={onboardingData.state.loading}
    >
      <Div mt={spacing(2)}>
        <InboxOptions onChange={handleChange} />
      </Div>

      <ButtonWrapper>
        <Button.Text onClick={onBack} color={colors.gray}>
          Back
        </Button.Text>

        <Button.Primary
          type="submit"
          height={48}
          onClick={() =>
            onboardingData.updateScraper(
              {
                scraperSettingsType: value,
              },
              () => onConclude(value),
            )
          }
        >
          Save Sync Settings
        </Button.Primary>
      </ButtonWrapper>
    </BaseStep>
  )
}
