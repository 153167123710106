import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Directions, ProgressIndicator } from '@basisboard/basis-ui/es/components/ProgressIndicator'
import { Subtitle } from '@basisboard/basis-ui/es/components/Typography'
import { colors, mediaBreakpointUp, spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import styled from 'styled-components'
import { OnboardingContainer } from './container'
import { Concluded } from './steps/Concluded'
import { EmailSync } from './steps/EmailSync'
import { Platforms } from './steps/Platforms'
import { UserDetails } from './steps/UserDetails'

const IndicatorWrapper = styled.div`
  width: 224px;
  align-self: stretch;
  border-right: 1px solid ${colors.blueGrayBorder};

  margin: ${spacing(-4)} 0;
  padding: ${spacing(4)} 0;
  padding-right: ${spacing(2)};

  display: none;

  ${mediaBreakpointUp('medium')} {
    display: block;
  }
`

const STEPS = [UserDetails, EmailSync, Platforms, Concluded]

export const Onboarding = () => {
  const onboardingData = useContainer(OnboardingContainer)

  const Step = STEPS[onboardingData.state.currentStep]

  if (onboardingData.state.currentStep === 3) {
    return <Step />
  }

  return (
    <>
      <IndicatorWrapper>
        <Subtitle mb={spacing(4)}>Getting Started</Subtitle>
        <Div height={280}>
          <ProgressIndicator
            direction={Directions.Vertical}
            indicators={[
              { title: 'Your Information' },
              { title: 'Sync Emails' },
              { title: 'Bidding Platforms' },
              { title: 'All Done!' },
            ]}
            showActive={!onboardingData.state.stepCompleted}
            current={onboardingData.state.currentStep}
          />
        </Div>
      </IndicatorWrapper>
      <Div
        pb={spacing(2)}
        width={[1, 1, 540]}
        pl={[0, 0, spacing(6)]}
        pr={[0, 0, spacing(2)]}
        flex={1}
        alignSelf="stretch"
      >
        <Step />
      </Div>
    </>
  )
}
