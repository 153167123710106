import { Platform } from '@basisboard/basis-common/lib/enums'
import { OptionBoxProps } from '../../components/OptionBox'

export const mapPlatformToImageProps: {
  [key in Platform]: OptionBoxProps['imageProps']
} = {
  [Platform.BuildingConnected]: {
    alt: 'Building Connected Logo',
    src: '/images/buildingconnected.png',
    style: { width: 50 },
  },
  [Platform.Planhub]: {
    alt: 'Plahub Logo',
    src: '/images/planhub.png',
    style: { width: 50 },
  },
  [Platform.ISQFT]: {
    alt: 'ISQFT Logo',
    src: '/images/isqft.png',
    style: { width: 50 },
  },
  [Platform.Procore]: {
    alt: 'Procore Logo',
    src: '/images/procore.png',
    style: { width: 50 },
  },
  [Platform.Bidmail]: {
    alt: 'Bidmail Logo',
    src: '/images/bidmail.png',
    style: { width: 100 },
  },
  [Platform.Gradebeam]: {
    alt: 'Gradebeam Logo',
    src: '/images/gradebeam.png',
    style: { width: 105 },
  },
  [Platform.TheBluebook]: {
    alt: 'Blue Book Logo',
    src: '/images/bluebook.png',
    style: { width: 110 },
  },
  [Platform.Smartbid]: {
    alt: 'Smart Bid',
    src: '/images/smartbid.png',
    style: { width: 93 },
  },
  [Platform.PipelineSuite]: {
    alt: 'Pipeline Suite',
    src: '/images/smartbid.png',
    style: { width: 150 },
  },
  [Platform.PanteraTools]: {
    alt: 'Pantera Tools',
    src: '/images/pantera.png',
    style: { width: 150 },
  },
  [Platform.ProcureWare]: {
    alt: 'Procure Ware',
    src: '/images/procureware.png',
    style: {},
  },
  [Platform.BidTracer]: {
    alt: 'Bidtracer',
    src: '/images/bidtracer.png',
    style: { width: 150 },
  },
  [Platform.Unknown]: {
    alt: 'Unknown platform',
    src: '/',
    style: {},
  },
}

export const SYNCABLE_PLATFORMS = [
  Platform.BuildingConnected,
  Platform.Planhub,
  Platform.ISQFT,
  Platform.Procore,
]
