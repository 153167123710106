import {
  borderRadiusMixin,
  colors,
  mediaBreakpointDown,
  spacing,
  transitionMixin,
} from '@basisboard/basis-ui/es/styles'
import styled, { css } from 'styled-components'

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;

  margin-top: ${spacing(6)};
  height: 48px;

  button > span {
    font-weight: 600;
  }

  ${mediaBreakpointDown('medium')} {
    flex-direction: column-reverse;
    align-items: stretch;
    height: auto;

    button:nth-child(1) {
      margin-top: ${spacing(2)};
    }

    margin-top: ${spacing(2)};
  }
`

export const OptionBox = styled.label<{ selected?: boolean }>`
  width: 108px;
  height: 126px;
  border: 1px solid ${colors.blueGrayBorder};
  margin: 0 ${spacing(1)};
  padding: ${spacing(2)} 0;
  position: relative;
  box-sizing: border-box;

  ${borderRadiusMixin};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${transitionMixin(undefined, 'border-color')};

  img {
    margin-bottom: ${spacing(2)};
  }

  ${({ selected }) =>
    selected &&
    css`
      margin: -1px ${spacing(1)};
      height: 128px;
      border-color: ${colors.blueGray600};
      border: 2px solid ${colors.blueGray600};
    `};
`
