import { GetProfileResponse } from '@basisboard/basis-common/lib/api'
import { config } from '../config'

declare global {
  interface Window {
    Intercom: (action: string, params?: unknown, extraParams?: unknown) => void
  }
}

export const bootIntercom = ({ name, email, trial }: Partial<GetProfileResponse>) =>
  window.Intercom('boot', {
    app_id: config.INTERCOM_APP_ID,
    created_at: Date.now(),
    name,
    email,
    trial,
  })
