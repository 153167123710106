"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimer = void 0;
const react_1 = require("react");
const utils_1 = require("../utils");
const MILISECONDS = 1;
const SECONDS = 1000 * MILISECONDS;
const MINUTES = 60 * SECONDS;
const useTimer = () => {
    const timer = (0, react_1.useRef)(Date.now());
    const startTimer = () => {
        timer.current = Date.now();
    };
    const getTimer = () => Date.now() - timer.current;
    const getDiffInSeconds = (time = getTimer()) => `${Math.round(time / SECONDS)}s`;
    const getDiffInMinutes = (time = getTimer()) => (0, utils_1.box)(Math.round(time / MINUTES)).fold(minutes => minutes === 0 ? getDiffInSeconds(time) : `${minutes}m${getDiffInSeconds(time % MINUTES)}`);
    return {
        startTimer,
        getTimer,
        getDiffInSeconds,
        getDiffInMinutes,
    };
};
exports.useTimer = useTimer;
