import { ScraperSettingsType } from '@basisboard/basis-common/lib/api/email-scraper'
import { Button } from '@basisboard/basis-ui/es/components/Button'
import { Div } from '@basisboard/basis-ui/es/components/Div'
import { Input } from '@basisboard/basis-ui/es/components/Input'
import { DetailText, Text } from '@basisboard/basis-ui/es/components/Typography'
import { useForm } from '@basisboard/basis-ui/es/hooks/useForm'
import { colors, spacing } from '@basisboard/basis-ui/es/styles'
import { useContainer } from '@containrz/react-hook'
import { rem } from 'polished'
import * as React from 'react'
import { BaseStep } from '../../../../components/BaseStep'
import { EventType, track } from '../../../../services'
import { OnboardingContainer } from '../../container'
import { ButtonWrapper } from '../../styled'
import { OptionsConfig } from './steps/OptionsConfig'
import { SelectScrapedPeriod } from './steps/SelectScrapedPeriod'
import { getNylasAuthUrl } from './utils'

export const EmailSync = () => {
  const onboardingData = useContainer(OnboardingContainer)
  const { loading, error } = onboardingData.state

  const [subStep, setSubstep] = React.useState(-1)

  const hasSettignsType = Boolean(onboardingData.state.customer.scraperSettingsType)
  const hasTimeFrame = Boolean(onboardingData.state.customer.scraperSettingsDelay)

  const createdScraperId =
    new URLSearchParams(window.location.search).get('emailScraperId') ||
    onboardingData.state.scraper?.id

  const { handleSubmit: handleEmailSubmit } = useForm<{ email: string }>(
    ({ email }, errors) => {
      if (Object.keys(errors).length > 0) {
        return
      }
      onboardingData.setState({ loading: true, error: false })
      window.location.href = getNylasAuthUrl(email, onboardingData.state.profile.id)
    },
    {
      validators: {
        email: (email: string) => ({
          canSubmit: email.length > 0,
          errorMessage: 'Please enter your email',
        }),
      },
    },
  )

  React.useEffect(() => {
    if (createdScraperId) {
      setSubstep(hasSettignsType ? 1 : hasTimeFrame ? 2 : 0)
    }
  }, [])

  React.useEffect(() => {
    track({ type: EventType.NavigatedToEmailSync })
  }, [])

  const onSkipSync = () => {
    onboardingData.moveToNextStep()
    track({ type: EventType.SkippedEmailSync })
  }

  if (subStep >= 0) {
    return [
      <OptionsConfig
        key="OptionsConfig"
        onConclude={scraperSettingsType =>
          setSubstep(scraperSettingsType === ScraperSettingsType.OnlyBasisBoardFolder ? 2 : 1)
        }
        onBack={() => setSubstep(-1)}
      />,
      <SelectScrapedPeriod
        key="SelectScrapedPeriod"
        onConclude={() => {
          setSubstep(2)
          onboardingData.setState({ stepCompleted: true })
        }}
        onBack={() => setSubstep(0)}
      />,
      <BaseStep
        key="Conclude"
        image="Check"
        textAlign="center"
        title="Successfully Connected!"
        description="You’re all set!"
      >
        <Div width={[1, 1, 267]} mx="auto" mt={spacing(5)}>
          <Button.Primary
            width={1}
            height={48}
            style={{ fontWeight: 600 }}
            onClick={onboardingData.moveToNextStep}
          >
            Next &gt; Bidding Platforms
          </Button.Primary>
        </Div>
      </BaseStep>,
    ][subStep]
  }

  return (
    <>
      <BaseStep
        image="Mailbox"
        textAlign="center"
        title="Connect your email account"
        description="Basis processes bid related emails, so you don’t have to."
        loading={loading}
      >
        <form onSubmit={handleEmailSubmit}>
          <Text mt={spacing(1)} mb={spacing(3)} fontWeight={400}>
            Enter your email address below to connect it to Basis.
          </Text>
          <Input width="100%" name="email" type="email" placeholder="Enter email address here" />

          <Div mx="auto">
            <Button.Text
              color={colors.mediumGray}
              style={{ fontSize: rem(12), textDecoration: 'underline' }}
              mt={spacing(4)}
              height={24}
              onClick={onSkipSync}
            >
              Skip Email Sync
            </Button.Text>
          </Div>

          {error && (
            <DetailText textAlign="center" color={colors.error} as="p" mt={spacing(2)}>
              {error}
            </DetailText>
          )}

          <ButtonWrapper>
            <Button.Primary ml="auto" type="submit" height={48}>
              Connect to Email
            </Button.Primary>
          </ButtonWrapper>
        </form>
      </BaseStep>
    </>
  )
}
